
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/sweetalert2/src/sweetalert2";
@import "node_modules/spinkit/scss/spinners/3-wave";

// loader
.sk-wave {
  .sk-rect {
    background-color: #D76C38;
  }
}

.m-b-15 {
  margin-bottom: 15px;
}


a {
  color: #D76C38;
}

html, body {
  height: 100%;
  margin: 0;
}

#app {
  min-height: 100%;

  /* Equal to height of footer */
  /* But also accounting for potential margin-bottom of last child */
  margin-bottom: -210px;
}

.push {
  height: 210px;
}

//fix navbar
@media (min-width: 768px) {
  .navbar-nav > li  > a {
    padding-top: 35px;
    padding-bottom: 35px;
    text-transform: uppercase;
    color: #fff;
  }
}


//sweetalert focus fix
button.swal2-confirm {
  &:focus {
    outline: none;
  }
}

.fade-enter-active {
  transition: opacity .5s
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.cam-brand {
  box-sizing: content-box;
  position: relative;
  display: inline-block;
  float: left;
  height: 4em;
  padding: .5em 0;
  transition: all .25s linear;
}

.cam-brand #svg-logo-cam {
  width: inherit;
  height: inherit;
  transition: all .25s linear;
}

.cam-fields {
  font-size: 18px;
  margin-top: 25px;
  label {
    color: #1a1919;
  }
}

.cam-table {
  margin-top: 25px;
  thead {
    th {
      background-color: #D76C38;
      color: #fff;
      text-transform: uppercase;
    }
  }
}

.cam-roof-table {
  .cam-td-code {

  }
}

.btn-group {
  .btn-cam {
    border: none;
    background-color: #F5F5F5;
    height: 50px;
    color: #8c8c8c;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
    &.router-link-active {
      color: #fff;
      background-color: #D76C38;
      &:hover {
        color: #fff;
      }
    }
    &:hover {
      border: #D76C38 1px solid;
      color: #8c8c8c;
    }
  }
  .btn-cam-sm {
    border: none;
    background-color: #F5F5F5;
    height: 30px;
    color: #8c8c8c;
    //font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
    &.active {
      color: #fff;
      background-color: #D76C38;
      &:hover {
        color: #fff;
      }
    }
    &:hover {
      border: #D76C38 1px solid;
      color: #8c8c8c;
    }
  }
}

.btn-cam {
  background-color: #D76C38;
  color: #fff;
  border-radius: 0;
  text-transform: uppercase;
  &:hover {
    color: #fff;
  }
  &.inverted {
    background-color: #fff;
    color: #D76C38;
    border: #D76C38 1px solid;
    &:hover {
      color: #D76C38;
    }
  }
}

//solutions
.cam-filters {
  margin-bottom: 50px;
}

.cam-solutions {
  margin-top: 50px;
  .cam-solution {
    .products {
      .product-summary {
        font-size: 0.9em;
        a {
          color: #1f1f1f;
        }
      }
    }
    .cam-solution-title {

    }
    .cam-solution-detail {
      padding: 15px 15px 50px;
      .products {
        margin-top: 25px;
        min-height: 60px;
        .product-group {
          margin-top: 15px;
          min-height: 30px;
        }
      }
      .cam-buttons-box {
        margin-top: 25px;
      }
    }
  }
}
.cam-price-box {
  font-family: "Open Sans", sans-serif;
  text-align: center;
  h4 {
    font-weight: 500;
    font-size: 28px;
    margin-bottom: 0;
  }
  .muted {
    color: #ccc;
  }
}

// footer
.prefooter {
  height: 130px;
  margin-top: 140px;
  background-color: #e6e6e6;
  border-top: 1px solid #ccc;
  padding-bottom: 40px;
  .partner-logo-ct {
    display: block;

    img {
      display: block;
      border: 0;
      max-width: 128px;
      padding: 20px 0 0;
      margin: 0 auto;
    }
    .partner-logo-bw {
      position: relative;
      display: block;
      opacity: 1;
      -webkit-transition: opacity .5s ease-in-out;
      -moz-transition: opacity .5s ease-in-out;
      -o-transition: opacity .5s ease-in-out;
      -ms-transition: opacity .5s ease-in-out;
      transition: opacity .5s ease-in-out;
    }
    .partner-logo-co {
      position: relative;
      left: -50%;
      -webkit-backface-visibility: hidden;
      display: none;
    }
    .partner-logo-c {
      position: absolute;
      top: 0;
      left: 50%;
    }
  }
}
.footer {
  width: 100%;
  height: 80px;
  padding: 30px 0 30px 0;
  background-color: #0C0C0C;
  color: #555;
  text-align: center;
}

@media (min-width: 768px) {
  .navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
    margin-left: 0;
  }
}

